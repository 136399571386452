/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "sv",
      "3166-1": "SE",
      "IETF": "sv-SE",
    },
    "CURRENCY": "SEK",
    "CURRENCY_SHORT": "kr",
    "TYPEKIT_ID": "thm8nca",
    "POINTS": "",
  },
  "URL": {
    "CHECKOUT_CART": "/checkout/cart",
    "CHECKOUT_OVERVIEW": "/checkout/overview",
    "CHECKOUT_SUCCESS": "/checkout/success",
    "CHECKOUT_FAILED": "/checkout/cart",
    "PRIVACY_POLICY": "/privacy-policy",
  },
  "LOGO": {
    "ALT": "Zupergift.com",
    "TITLE": "Gå till startsidan",
  },
  "COOKIE_CONSENT": {
    "TEXT": "För att förbättra och anpassa din upplevelse av våra digitala tjänster använder Zupergift cookies. Läs vår <a href=\"/se/privacy-policy\">Integritetspolicy</a> om du vill veta mer om cookies.",
    "ALL": "Tillåt alla",
    "SELECTED": "Tillåt urval",
    "NECESSARY": "Tillåt nödvändiga",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Nödvändiga",
    "STATISTICAL": "Statistiska",
    "MARKETING": "Marknadsföring",
  },
  "ROUTES": {
    "ALL_GIFTCARDS": "/alla-presentkort",
  },
  "HOMEVIEW": {
    "HERO_ALT": "Zupergift.com",
    "REDEEM_CTA": "Lös in din Zupergift",
    "EXPLORE_CTA": "Utforska presentkort",
    "EXPLORE_LINK": "/alla-presentkort",
    "ZUPERGIFT_CTA": "Köp Zupergift",
    "ZUPERGIFT_LINK": "/zupergift",
    "POPULAR_BRANDS": "Populära varumärken",
    "POPULAR_CATEGORIES": "Populära kategorier",
    "POPULAR_PRODUCTS": "Populära presentkort",
    "SEE_MORE": "Se fler",
    "ALL_CATEGORIES": "Alla kategorier",
    "MORE_POPULAR_PRODUCTS": "Fler populära produkter",
    "BECOME_CUSTOMER": "Bli kund",
    "TUTORIAL": "Så fungerar det",
  },
  "TUTORIAL": {
    "STEP_1": {
      "TITLE": "1",
      "LINK_END": "här",
      "TEXT": "Köp Zupergift",
      "LINK": "/zupergift",
    },
    "STEP_2": {
      "TITLE": "2",
      "TEXT": "Leverans direkt via e-post",
    },
    "STEP_3": {
      "TITLE": "3",
      "LINK_END": "här",
      "TEXT": "Lös in Zupergift",
      "LINK": "/redeem",
    },
    "STEP_4": {
      "TITLE": "4",
      "LINK_END": "här",
      "TEXT": "Välj presentkort ",
      "LINK": "/alla-presentkort",
    },
  },
  "LOGIN": {
    "TITLE": "Logga in som kund",
    "EMAIL": "Din mailadress",
    "CUSTOMER_NUMBER": "Kundnummer du vill logga in som",
    "PASSWORD": "Lösenord",
  },
  "REDEEM": {
    "HEADING": "Lös in ditt presentkort",
    "TEXT": "Börja med att ange din kod nedan. Koden hittar du i mejlet som innehöll ditt Zupergift-presentkort. I nästa steg väljer du vilket eller vilka varumärken du vill lösa in ditt presentkort till. Därefter går du till kassan och sedan landar dina val av presentkort i din e-post. Zuperenkelt!",
    "PLACEHOLDER_CODE": "Ange kod",
    "PLACEHOLDER_PIN": "Ange pin",
    "SUBMIT": "Spara",
    "SUCCESS_ROUTE": "/alla-presentkort",
  },
  "ACCOUNT": {
    "LOGIN": "Logga in",
    "LOGOUT": "Logga ut",
    "MY_ACCOUNT": "Mitt konto",
    "USER": "Användare",
    "SHIPPING_ADDRESS": "Leveransadress",
    "WISHLIST": "Önskelista",
    "EMAIL": "E-mail",
    "BALANCE": "Saldo",
    "DATE": "Datum",
    "SEE_ALL": "Se alla",
    "EDIT": "Ändra",
    "YOUR_BALANCE": "Ditt saldo är %amount",
  },
  "WISHLIST": {
    "TITLE": "Önskelista",
    "BACK": "Gå till mitt konto",
  },
  "ADDRESS": {
    "FIRSTNAME": "Förnamn",
    "LASTNAME": "Efternamn",
    "STREET_1": "Gatuadress 1",
    "STREET_2": "Gatuadress 2 (frivilligt)",
    "POSTCODE": "Postnummer",
    "CITY": "Stad",
    "COUNTRY": "Land",
    "EMAIL": "E-mail",
    "TELEPHONE": "Telefon",
  },
  "OR": "eller",
  "VALUE": "Värde",
  "CART": {
    "DISTRIBUTION": "Betalningsfördelning",
    "HEADING": "Varukorg",
    "TOTALS": "Totalt",
    "YOUR_BALANCE": "Ditt saldo",
    "AFTER_PURCHASE": "Saldo efter köp",
    "VALID_TO": "Giltig tom",
    "PAY_CASH": "Kort/faktura",
    "PAY_VOUCHER": "Presentkort",
    "EMPTY": "Varukorgen är tom",
    "INCREMENT": "Öka kvantitet",
    "DECREMENT": "Minska kvantitet",
    "QUANTITY": "Kvantitet",
    "CTA": "Gå till varukorgen",
    "PRODUCTS": "Produkter",
    "SHIPPING": "Frakt",
    "SUMMARY": "Summering",
    "TO_CHECKOUT": "Gå till kassan",
    "MOVE_TO_WISHLIST": "Flytta till önskelista",
    "DIALOG_CANCEL": "Avbryt",
    "REMOVE_PRODUCT": "Ta bort produkt",
    "REMOVE_APPROVE": "Ja, ta bort",
    "CONFIRM_TEXT": "Vill du verkligen ta bort '%itemName' från varukorgen?",
    "CONTINUE_SHOPPING": "Fortsätt handla",
    "DISCOUNT": "Rabatt",
    "SUB_TOTAL": "Summa",
    "SUB_TOTAL_WITH_DISCOUNT": "Delsumma exkl. rabatt",
    "GRAND_TOTAL": "Totalt att betala",
  },
  "CHECKOUT": {
    "TITLE": "Kassa",
    "CART": "Din varukorg innehåller",
    "CONTINUE": "Fortsätt",
    "CUSTOMER_INFORMATION": "Kunduppgifter",
    "REDEEM_NOTICE": "Nu är det dags att slutföra ditt köp och därmed ange koden på ditt Zupergift-presentkort.",
    "DELIVERY_NOTICE": "När du godkänt och löst in din Zupergift skickas valda varumärkespresentkort till den e-postadress som du angivit.",
    "CONTACT_CUSTOMER_SERVICE": "Kontakta kundtjänst",
    "PLACE_ORDER": "Godkänn och lös in",
    "TO_PAYMENT": "Gå till betalning",
    "EMAIL": "E-postadress",
    "EMAIL_REPEAT": "Upprepa e-postadress",
    "TELEPHONE": "Mobilnummer",
    "AMOUNT_TO_PAY": "Att betala",
    "PAY_WITH_CARD": "Betala med kort",
    "EDIT": "Ändra",
    "DELIVERY": "Leverans",
    "SENT_TO": "Skickas till",
    "CONTINUE_SHOPPING": "Fortsätt handla",
    "BACK_TO_CART_TEXT": "Tillbaka till varukorgen",
    "COMPLETE_PURCHASE": "Slutför köp",
    "CARD_PAYMENT": "Kortbetalning",
    "REDEEM_LABEL_CODE": "Presentkortskod",
    "REDEEM_LABEL_PIN": "Ange tillhörande pinkod",
    "REDEEM_SUBMIT": "Använd",
    "REDEEM_AMOUNT": "Din Zupergift innehåller totalt %value.",
    "REDEEM_ERROR_INSUFFICIENT": "Du har %value på ditt Zupergift-presentkort. Nedan ser du vilka varumärkespresentkort du lagt i din varukorg och hur mycket du har kvar på ditt Zupergift-presentkort efter köpet.",
    "REDEEM_BUTTON_LABEL": "Lös in Zupergift",
    "OPT_EMAIL": "Jag vill få information om erbjudanden från Zupergift",
    "I_ACCEPT_THE": "Jag accepterar",
    "TERMS": "köpvillkoren",
    "TERMS_URL": "villkor",
    "PAYMENT_METHOD_STEP": "Välj betalningsmetod",
    "PAY_STEP": "Gå till säker betalning",
    "ADDRESS": {
      "ADDRESS": "Faktura & leveransinformation",
      "SHIPPING": "Leveransadress",
      "BILLING": "Kvittoadress",
      "SHIP_TO_SAME_ADDRESS": "Leverera till samma adress",
      "CONTACT_INFO": "Kontaktuppgifter",
      "EMPTY_ADDRESS": "Adressen är inte komplett, vänligen uppdatera",
    },
    "DISCOUNT_CODE": {
      "HEADER": "Rabattkod",
      "PLACEHOLDER": "Ange rabattkod",
      "APPLY": "Använd",
      "REMOVE": "Ta bort",
    },
  },
  "PAYMENT": {
    "TITLE": "Hur vill du betala?",
    "USE_PAY": "Betala nu",
    "USE_CARD": "Betala med kort",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
    "ALTAPAY": {
      "CARD": "Betala med kort" },
  },
  "PAYMENT_LIMIT": {
    "TEXT_ONE": "Det är för tillfället inte möjligt att handla för mer än %amount avseende köp som betalas med pengar. Presentkort inlöses som vanligt.",
    "TEXT_TWO": "Vänligen kontakta kundtjänst för mer information på <a href=\"mailto:info@awardit.com\">info@awardit.com</a>.",
  },
  "SUCCESS": {
    "HELMET_TITLE": "Orderbekräftelse",
    "TITLE": "Beställning registrerad",
    "SUB_TITLE": "Ordernummer %number",
    "BACK": "Fortsätt handla",
    "NOT_FOUND_TITLE": "Ingen order hittades.",
    "NOT_FOUND_SUBTITLE": "Vänligen kontakta kundtjänst om detta är felaktigt.",
    "WHAT_NOW": "Så vad händer nu?",
    "STEPS": {
      "HEADING": "Beställning registrerad",
      "WHAT_NOW": "Så vad händer nu?",
      "LABEL": "Din beställning",
      "ORDER_ID": "Ordernummer %id",
      "QUESTIONS": {
        "HEADING": "Har du en fråga om din beställning?",
        "LABEL": "Gå till kundtjänst",
        "URL": "/faq",
      },
      "NORMAL": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "En orderbekräftelse kommer att skickas till %email inom kort.",
        },
        "2": {
          "HEADING": "Bearbetning",
          "TEXT": "Din beställning skickas när samtliga produkter finns tillgängliga för leverans. Vanligen sker detta redan nästkommande dag.",
        },
        "3": {
          "HEADING": "Transport",
          "TEXT": "När dina varor packats och skickats så meddelas du via e‑post. I leveransbekräftelsen finner du en spårningslänk med vilken du kan följa paketets väg. Leveransen tar normalt 1–2 dagar.",
        },
        "4": {
          "HEADING": "Leverans",
          "TEXT": "Vissa paket levereras direkt i din brevlåda. Paket som är för stora levereras i stället till ett utlämningsställe och aviseras via sms till det mobilnummer du har angivit i beställningen. Paket ligger kvar på utlämningsstället i 14 dagar.",
        },
      },
      "MIXED": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "En orderbekräftelse och dina digitala biljetter/värdekoder kommer att skickas till %email inom kort. Har du inte fått någon e‑post ber vi dig dubbelkolla ditt spam-filter innan du kontaktar kundservice.",
        },
        "2": {
          "HEADING": "Bearbetning",
          "TEXT": "Din beställning skickas när samtliga produkter finns tillgängliga för leverans. Vanligen sker detta redan nästkommande dag.",
        },
        "3": {
          "HEADING": "Transport",
          "TEXT": "När dina varor packats och skickats så meddelas du via e‑post. I leveransbekräftelsen finner du en spårningslänk med vilken du kan följa paketets väg. Leveransen tar normalt 1–2 dagar.",
        },
        "4": {
          "HEADING": "Leverans",
          "TEXT": "Vi skickar dina digitala biljetter/värdekoder till %email inom kort. Din fysiska leverans skickas med DBSchenker. Vissa paket levereras direkt i din brevlåda. Paket som är för stora levereras i stället till ett utlämningsställe och aviseras via sms till det mobilnummer du har angivit i beställningen. Paket ligger kvar på utlämningsstället i 14 dagar.",
        },
      },
      "VIRTUAL": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "Du kommer att få en orderbekräftelse till %email.",
        },
        "2": {
          "HEADING": "Digital leverans",
          "TEXT": "Vi skickar dina digitala biljetter/värdekoder till %email inom kort. Har du inte fått någon e‑post ber vi dig dubbelkolla ditt spam-filter innan du kontaktar kundservice.",
        },
      },
    },
    "CONTACT": {
      "HEADING": "Undrar du något?",
      "TEXT": "Vi svarar på dina frågor angående leverans, produkter eller sortimentet inom 48 timmar på helgfria vardagar.",
      "LINK": "http://helpdesk.crossroads.se/?s=15e4e4fcc649e015e4e4fcc64a16",
      "LINK_TEXT": "Skriv till oss via vårt kontaktformulär",
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hej! Du använder en utdaterad webbläsare. Vänligen <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">uppdatera din webbläsare</a> för att använda zupergift.com",
  },
  "CATEGORY": {
    "EMPTY": "Det finns inga produkter att visa just nu",
    "LOAD_MORE": "Ladda fler",
    "PRODUCTS_IN_CATEGORY_TITLE": "Visar %itemsCount produkter i",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Visar 1 produkt i",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Visar %itemsCount produkter i",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Visar produkter i",
  },
  "ALL_GIFTCARDS": {
    "TITLE": "Alla presentkort",
  },
  "404": {
    "TITLE": "Sidan kunde inte hittas",
    "HEADING": "— 404 —",
    "SUBHEADING": "Sidan kunde inte hittas",
  },
  "SERVER_ERROR": {
    "TITLE": "Serverfel",
    "HEADING": "Serverfel: 500",
    "SUBHEADING": "Vänligen återkom inom kort!",
    "TEXT": "<strong>Behöver du kontakta oss?</strong><br/> Skicka ett mail till <a href=\"mailto:kundservice@crossroads.se\">kundservice@crossroads.se</a>",
  },
  "SEARCH": {
    "TITLE_PREFIX": "Sök: ",
    "PLACEHOLDER": "Sök efter ett presentkort",
    "SEARCH_RESULT": "Hittade 1 produkt som matchade",
    "PRODUCTS_IN_SEARCH_RESULT": "Hittade %itemsCount produkter som matchade",
    "PRODUCT_IN_SEARCH_RESULT": "Hittade en produkt som matchade",
  },
  "FILTER": {
    "FILTERBAR": {
      "ALL_FILTERS": "Alla filter",
      "FILTER_BY_POINTS": "Filtrera på poäng",
      "FILTER_BY_PRICE": "Filtrera på pris",
      "PRICE_RANGE": "%min - %max kr",
      "SORT": "Sortera",
      "SORT_PRICE_DESC": "Pris ↓",
      "SORT_PRICE_ASC": "Pris ↑",
      "SORT_NAME_DESC": "Namn ↓",
      "SORT_NAME_ASC": "Namn  ↑",
    },
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT_TOTAL_SINGLE": "%total Produkt",
      "SHOW_RESULT_TOTAL_MULTIPLE": "%total Produkter",
      "SHOW_RESULT": "Visa resultat",
      "CLOSE_MENU": "Stäng filter",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Rensa filter",
      "NO_ACTIVE_FILTERS": "Du har inga aktiva filter",
      "PRICE_RANGE": "Pris (%range)",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filtrera på poäng",
      "FILTER_BY_POINTS": "Filtrera på pris",
      "RESET_PRICE": "Rensa filter",
      "PRICE_FROM": "Från",
      "PRICE_TO": "Till",
    },
  },
  "HEADER": {
    "CLOSE_MENU": "Stäng menyn",
    "SEARCH": "Sök",
    "ALL_GIFTCARDS": "Alla presentkort",
    "BUY_ZUPERGIFT": "Köp Zupergift",
    "REDEEM_GIFTCARD": "Lös in Zupergift",
    "CATEGORIES": "Kategorier",
    "CART": "Varukorg",
    "HELP": "Kontakt",
    "ABOUT": "Hur fungerar det?",
    "CLOSE_CATEGORIES": "Stäng kategorier",
    "BRANDS": "Varumärken",
    "CLOSE_BRANDS": "Stäng varumärken",
    "ACCOUNT": "Mitt konto",
    "CUSTOMER_SERVICE": "Kundservice",
    "BANNER": {
      "TEXT": "Börja prenumerera på vårt nyhetsbrev och ta del av exklusiva erbjudanden och rabatter.",
      "BUTTON": "Registrera dig här",
    },
  },
  "NEWSLETTERBANNER": {
    "HEADER": "Prenumerera på vårt nyhetsbrev och ta del av exklusiva erbjudanden och rabatter!",
    "FORM": {
      "PLACEHOLDER": "Min epostadress",
      "BUTTON_TEXT": "Prenumerera",
    },
    "TERMS": {
      "TEXT": "Läs om vår <a href=\"/se/privacy-policy\">Integritetspolicy</a>",
    },
  },
  "FOOTER": {
    "CURRENT_LANG": "Du besøker vår svenske Zupergift-butikk",
    "CHANGE_LANG": "Bytt til vår norske butikk",
    "CHANGE_LANG_URL": "https://zupergift.com/no/",
    "CATEGORIES": "Kategorier",
    "COPYRIGHT": "Copyright © %currentYear",
    "RECAPTCHA": "This site is protected by reCAPTCHA and the Google <a href=\"https://policies.google.com/privacy\">Privacy Policy</a> and <a href=\"https://policies.google.com/terms\">Terms of Service</a> apply.",
    "LANGUAGE_SWITCHER_TEXT": "You're using the Swedish version of Zupergift",
    "LANGUAGE_SWITCHER_BUTTON": "Change language/region",
    "COOKIES": "Hantera cookies",
    "TERMS_LINK": "villkor",
    "TERMS_TEXT": "Köpvillkor",
  },
  "LANGUAGE_SWITCHER": {
    "TITLE": "Welcome!",
    "SUB_TITLE": "You're using the Swedish version of Zupergift. Do you want to change language/region?",
    "FINLAND": "Finland",
    "DENMARK": "Denmark",
    "NORWAY": "Norway",
    "SWEDEN": "Sweden",
  },
  "BREADCRUMBS": {
    "HOME_URL": "/",
    "HOME": "Hem",
    "SEARCH_RESULTS_FOR": "Sökresultat för: ",
  },
  "PRODUCT": {
    "STARTING_AT": "Från",
    "ADD_TO_CART": "Lägg i varukorg",
    "OUT_OF_STOCK": "Slut i lager",
    "TOO_LOW_BALANCE": "För lågt saldo",
    "DESCRIPTION": "Produktbeskrivning",
    "OTHERS_ALSO_LIKED": "Andra gillade också",
    "OPTION_PLACEHOLDER": "Välj %attributeLabel",
    "LOGIN_TO_PURCHASE": "Logga in för att kunna handla",
    "CHANGE_IMAGE": "Byt bild",
    "OPTION_DROPDOWN_PLACEHOLDER": "Välj ett alternativ...",
  },
  "VALIDATION": {
    "REQUIRED": "Detta fält är obligatoriskt.",
    "EMAIL": "Vänligen fyll i en korrekt e‑postadress.",
    "PHONE": "Vänligen fyll i ett korrekt mobiltelefonnummer.",
    "NUMERIC": "Fältet får bara innehålla nummer.",
    "LENGTH_LT": "Fältet innehåller för många tecken.",
    "POSTCODE": "Vänligen fyll i ett korrekt postnummer. (XXX XX)",
    "MATCH": "Fältet matchar inte.",
    "PHONE_LENGTH_COLLECTOR": "Mobilnumret är för kort.",
  },
  "STATUS_CODE": {
    /* Client */
    "UNKNOWN": "Ett okänt fel har uppstått. Vänligen ladda om sidan och försök igen.",
    "NETWORK_ERROR": "Anropet avbröts. Vänligen verifiera att du har en aktiv internetanslutning eller försök igen om en stund.",
    "PLACE_ORDER_CATCH": "Ett fel uppstod. Vänligen försök igen senare.",

    "COLLECTOR": {
      "SESSION_EXPIRED": "Sessionen med Collector har upphört. Vänligen ladda om sidan.",
      "NUM_TRIES_EXCEEDED": "Kunde inte hämta ordern, vänligen kontakta kundtjänst.",
    },

    "errorProductQuantityNotAvailable": "Det antal du angav finns inte tillgängligt just nu.",
    "errorProductNotInStock": "Produkten du försökte lägga i varukorgen finns dessvärre inte i lager just nu.",
    "errorProductMaxQuantity": "Det angivna antalet finns dessvärre inte i lager just nu.",
    "errorProductNotChosenVariant": "Vänligen välj %attributeLabel för att kunna lägga till produkten i din varukorg.",
    "termsNotAccepted": "Du måste godkänna köpvillkoren för att genomföra ditt köp.",
    "errorBillingAddressValidationFailed": "Felaktig adress",
    "REDEEM_ERROR": {
      "_777": "Presentkortskoden är inte giltig. Kontrollera koden och försök igen.",
      "_786": "Okänt fel vid validering av koden. Kontrollera koden och försök igen.",
      "_791": "Presentkortskoden kräver en pinkod. Vänligen fyll i tillhörande pinkod och försök igen.",
      "_792": "Pinkoden är inte giltig. Kontrollera pinkoden och försök igen.",
      "RECAPTCHA": "Google ReCaptcha kunde inte valideras. Vänligen försök igen, eller testa en annan enhet och/eller webbläsare.",
    },
    "NEWSLETTER": {
      "SUCCESS": "Epost registrerad.",
      "ERROR": {
        "notModified": "Kunde inte lägga till e-post. Det är möjligt att e-postadressen redan används.",
        "invalidEmail": "Den angivna e-postadressen är ogiltig.",
        "notLoggedIn": "Var vänlig ange en giltig e-postadress. E-postfältet kan inte lämnas tomt.",
      },
    },
    "success": "Ditt presentkort är aktiverat och du kan nu lösa in värdet!",

    "NOT_ALLOWED_TO_BUY_PRODUCT_WITH_CURRENT_GIFTCODE": "Denna produkt kan inte betalas med den Zupergift-kod du just nu har aktiv. Välj en annan produkt.",
    "NOT_ALLOWED_TO_USE_GIFTCODE_WITH_CURRENT_PRODUCTS": "Vissa av produkterna i varukorgen kan inte köpas med denna koden och har därför plockats bort.",
    "NOT_ALLOWED_TO_PLACE_ORDER_WITH_PRODUCTS": "Det går inte att slutföra köpet, då din varukorg innehåller produkter som inte är tillåtna att köpas med den Zupergift-kod du just nu har aktiv.",
    "DISCOUNT_CODE_FAILURE": "Rabattkoden kunde inte appliceras, vänligt försök igen eller kontakta kundtjänst.",
    "DISCOUNT_CODE_APPLIED": "Rabattkod applicerad.",
  },
};
